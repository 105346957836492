<template>
  <div>
    <!-- 个人积分 -->
    <div style="position: absolute; height: 100%; right: 300px; top:0">
      <fieldset
        style="border-color: rgb(200,200,200); border-width: 1px; border-style: solid; padding: 0 20px 5px 20px; box-sizing: border-box"
      >
        <legend style="color: #777777; height: 20px; font-size: 12px; ">
          个人积分
        </legend>
        <div style="display: flex">
          <importButton
            :import-url="`/admin/match/${type_id}/import_member_score`"
            :status-url="`/admin/match/${type_id}/import_member_score_status`"
            :data="{ match_id: match_id }"
            @refushList="refushList('person')"
          ></importButton>
          <!-- import_template使用s1模板是因为模板通用，不需要区分 -->
          <importHistoryButton
            :history_url="`/admin/match/${type_id}/import_member_score_history`"
            :import_template="`import_s1_member_score_league`"
          ></importHistoryButton>
        </div>
      </fieldset>
    </div>

    <div style="position: absolute; height: 100%; right: 0; top:0">
      <fieldset
        style="border-color: rgb(200,200,200); border-width: 1px; border-style: solid; padding: 0 20px 5px 20px; box-sizing: border-box"
      >
        <legend style="color: #777777; height: 20px; font-size: 12px; ">
          门派积分
        </legend>
        <div style="display: flex">
          <importButton
            :import-url="`/admin/match/${type_id}/import_team_score`"
            :status-url="`/admin/match/${type_id}/import_team_score_status`"
            :data="{ match_id: match_id }"
            @refushList="refushList('team')"
          ></importButton>
          <!-- import_template使用s1模板是因为模板通用，不需要区分 -->
          <importHistoryButton
            :history_url="`/admin/match/${type_id}/import_team_score_history`"
            :import_template="`import_s1_team_score_league`"
          ></importHistoryButton>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<script>
import importButton from "../../components/importButton";
import importHistoryButton from "../../components/importHistoryButton";
export default {
  data() {
    return {
      type_id: "",
      match_id: "",
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        this.type_id = val.query.type_id;
        this.match_id = val.query._id;
      },
      immediate: true,
    },
  },
  components: {
    importButton,
    importHistoryButton,
  },
  methods: {
    refushList(type) {
      this.$bus.$emit("onRefushList", type);
    },
  },
};
</script>
<style></style>
